import type { HTMLAttributes } from 'react';
import { cn } from '~/utils/cn';

export function Container({
  className,
  ...restProps
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn('container mx-auto px-4', className)} {...restProps} />
  );
}
